import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { AnimationTrail } from 'shared/Animation/Animation'
import Layout from 'shared/Layout/Layout'
import Seo from 'shared/Seo/Seo'
import AnchorButton from 'shared/Button/AnchorButton'

interface Datas {
  markdownRemark: {
    frontmatter: {
      error: {
        slug: string
        title: string
        subtitle: string
        link: string
        text: string
        image: {
          alt: string
          src: any
        }
      }
    }
  }
}

const s = {
  container: `w-full h-screen`,
  wrapper: `max-w-screen-wrapper w-11/12 mx-auto`,

  content: `absolute top-2/4 -translate-y-2/4 z-2 w-w65 h-fit text-white 
  mdx:w-fit smm:mt-8`,
  title: `-mt-4`,
  subtitle: `-mt-10 mb-20 text-clamp-3xl`,

  image: `absolute top-0 w-full h-full`,
  overlay: `absolute top-0 left-0 z-1 w-full h-full bg-opblack`,
}

const NotFoundPage = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/error/" }) {
        frontmatter {
          error {
            slug
            title
            subtitle
            link
            text
            image {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 80
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  `) as Datas

  const { title, subtitle, link, text, image } =
    datas.markdownRemark.frontmatter.error

  return (
    <Layout backgrounds="transparent">
      <Seo title={title} description={subtitle} />
      <section className={s.container}>
        <GatsbyImage
          className={s.image}
          image={image.src.childImageSharp.gatsbyImageData}
          alt={image.alt}
        />
        <div className={s.overlay} />
        <div className={`${s.wrapper}`}>
          <div className={s.content}>
            <AnimationTrail delay={300} x={0} y={100}>
              <h1 className={s.title}>{title}</h1>

              <p className={s.subtitle}>{subtitle}</p>

              <AnchorButton to={link} classname="">
                {text}
              </AnchorButton>
            </AnimationTrail>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
